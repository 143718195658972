////
/// @group _settings/
////


/// Карта Шрифтов
/// @type {Map}
$font-family: (
	main: #{"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif},
	form: sans-serif,
	sub: sans-serif,
	monospace: #{Consolas, "Liberation Mono", Menlo, Courier, monospace}
);
